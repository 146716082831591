<template>
  <div>
    <section class="about--sec about--abt" :id="slug">
      <h2>{{ data.title }}</h2>
      <div class="about--sec--ctRow">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="about--abt--text">
              <p>{{ data.description }}</p>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="about--abt--img">
              <div class="imgwrap">
                <div
                  class="about--imgItem"
                  :style="{
                    backgroundImage: `url(${data.featuredImage.large})`
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--end of about us section -->
  </div>
</template>
<script>
export default {
  name: "about",
  data() {
    return {};
  },
  props: {
    data: Object,
    slug: String
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.about {
  &--sec {
    margin-bottom: 40px;
    @include respond-to(screen-md) {
      margin-bottom: 80px;
    }
    h2 {
      font-size: $fs-r-xl;
      font-weight: 400;
      margin-bottom: 20px;
      @include respond-to(screen-md) {
        font-size: $fs-xxl;
      }
    }
    p {
      margin-bottom: 10px;
    }
    h3 {
      font-size: $fs-r-xl;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }
  &--top {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  &--imgItem:after {
    padding-top: 66.66%;
  }
  &--abt {
    &--img {
      margin-bottom: 30px;
      @include respond-to(screen-md) {
        margin-bottom: 0;
      }
    }
    &--text {
      padding-right: 0;
      margin-bottom: 30px;
      @include respond-to(screen-md) {
        padding-right: 40px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
